import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementTWLocale from 'element-ui/lib/locale/lang/zh-TW'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';// element-ui lang
import zhLocale from './zh-CN';
import twLocale from './zh-TW';
import { appConfigModule } from '@/store/modules/appConfig';

Vue.use(VueI18n);

const messages = {
    tw: {
        ...twLocale,
        ...elementTWLocale
    },
    zh: {
        ...zhLocale,
        ...elementZhLocale
    }
};
export function getLanguage() {
    const defaultLanguage = 'tw';

    // // 如果是泽宝
    // // eslint-disable-next-line eqeqeq
    // if (appConfigModule.appConfig.shopId == '10') {
    //     // defaultLanguage = 'zh';
    // }
    const chooseLanguage = Cookies.get('language') || appConfigModule.appConfig.language1 || defaultLanguage;
    if (chooseLanguage) { return chooseLanguage; }

    // 如果没有选择语言
    const newNavigator: any = navigator;
    const language = (newNavigator.language || newNavigator.browserLanguage).toLowerCase();
    const locales = Object.keys(messages);
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
            return locale;
        }
    }
    return appConfigModule.appConfig.language1 || defaultLanguage;
}

const i18n = new VueI18n({
    // 设置本地语言
    locale: getLanguage(),
    messages
});

export default i18n;
